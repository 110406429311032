import { DictionaryInterface } from 'titus-ts/dist/js/Common/Util/DictionaryInterface';
import BuiltInProperties from 'titus-ts/dist/js/Document/BuiltInProperties';
import { AddinApi } from './AddinApi';

export class WordApi extends AddinApi {
    constructor() {
        super();
    }

    public getBodyAsTextAsync = (): Promise<string> => {
        return new Promise<string>((resolve, reject) => {
            Word.run(async (context) => {
                const body = context.document.body;
                context.load(body);
                await context.sync();
                if (!body) reject('word getBodyAsTextAsync failed');
                else {
                    let text = '';
                    context.load(body.paragraphs);
                    await context.sync();
                    body.paragraphs.items.forEach((item, index, arr) => {
                        text += item.text;
                        if (index !== arr.length - 1) {
                            text += '\n';
                        }
                    });
                    resolve(text);
                }
            });
        });
    };

    public getHeaderContent = (): Promise<string> => {
        return new Promise((resolve) => {
            Word.run(async (context) => {
                const firstSection = context.document.sections.getFirstOrNullObject();
                if (firstSection) {
                    const header = firstSection.getHeader('Primary');
                    context.load(header);
                    await context.sync();
                    resolve(header.text);
                }
                resolve('');
            });
        });
    };

    public getFooterContent = (): Promise<string> => {
        return new Promise((resolve) => {
            Word.run(async (context) => {
                const firstSection = context.document.sections.getFirstOrNullObject();
                if (firstSection) {
                    const footer = firstSection.getFooter('Primary');
                    context.load(footer);
                    await context.sync();
                    resolve(footer.text);
                }
                resolve('');
            });
        });
    };

    public getProperties = (): Promise<BuiltInProperties> => {
        return new Promise<BuiltInProperties>((resolve, reject) => {
            Word.run(async (context) => {
                const properties = context.document.properties;
                context.load(properties);
                await context.sync();
                if (!properties) reject('Word properties is null');
                else {
                    const docProperties: BuiltInProperties = {
                        Author: properties.author,
                        Category: properties.category,
                        Comments: properties.comments,
                        CreationDate: properties.creationDate?.toDateString(),
                        Keywords: properties.keywords,
                        LastAuthor: properties.lastAuthor,
                        LastPrintDate: properties.lastPrintDate?.toDateString(),
                        LastSaveTime: properties.lastSaveTime?.toDateString(),
                        RevisionNumber: properties.revisionNumber,
                        Subject: properties.subject,
                        Template: properties.template,
                        Title: properties.title,
                        NumberOfPages: '',
                    };
                    resolve(docProperties);
                }
            });
        });
    };

    public getCustomProperties = (
        requestedCustomPropertiesNames: string[],
    ): Promise<DictionaryInterface<string | string[]>> => {
        return new Promise<DictionaryInterface<string | string[]>>((resolve, reject) => {
            Word.run(async (context) => {
                const selectedCustomProperties: DictionaryInterface<string | string[]> = {};
                let successful = true;
                let errorCount = 0;
                do {
                    try {
                        const customProperties = context.document.properties.customProperties;
                        context.load(customProperties);
                        await context.sync();
                        if (!customProperties.items) reject('Word get Custom Properties failed');
                        else {
                            customProperties.items.forEach((item) => {
                                if (requestedCustomPropertiesNames.includes(item.key)) {
                                    selectedCustomProperties[item.key] = item.value.split(';');
                                }
                            });
                        }
                        successful = true;
                    } catch {
                        errorCount += 1;
                        await this.sleep(1000);
                        if (errorCount === 5) reject(new Error('customProperties could not be loaded'));
                        successful = false;
                    }
                } while (!successful);
                resolve(selectedCustomProperties);
            });
        });
    };

    public setCustomProperties = (
        selectedCustomProperties: DictionaryInterface<string | string[]>,
        associatedCustomPropertiesNames: string[],
    ): Promise<void> => {
        return new Promise<void>((resolve) => {
            Word.run(async (context) => {
                const fields = Object.getOwnPropertyNames(selectedCustomProperties);
                const customProperties = context.document.properties.customProperties;
                context.load(customProperties);
                for (let i = 0; i < associatedCustomPropertiesNames.length; i++) {
                    customProperties.getItemOrNullObject(associatedCustomPropertiesNames[i])?.delete();
                }
                for (let i = 0; i < fields.length; i++) {
                    const valueString =
                        typeof selectedCustomProperties[fields[i]] == 'string'
                            ? selectedCustomProperties[fields[i]]
                            : (selectedCustomProperties[fields[i]] as string[]).join(';');
                    context.document.properties.customProperties.add(fields[i], valueString);
                }
                await context.sync();
                resolve();
            });
        });
    };

    private sleep = (milliseconds: number): Promise<void> => {
        return new Promise((resolve) => setTimeout(resolve, milliseconds));
    };

    public getPlatform = (): string => {
        const platformType = this.getPlatformType();
        let platform: string;
        switch (platformType) {
            case 'Android':
                platform = 'WordAndroid';
                break;
            case 'iOS':
            case 'Mac':
                platform = 'WordIOS';
                break;
            case 'OfficeOnline':
                platform = 'WordWebApp';
                break;
            default:
                platform = 'Word';
                break;
        }
        return platform;
    };

    public parseSections = (sections: string): number[] => {
        if (!isNaN(Number(sections))) {
            // if the input is a number, shorcut and return that wrapped in an array
            return [Number(sections)];
        }

        const result: number[] = [];

        // split the input string by commas to get individual sections or section ranges
        sections.split(',').forEach((section) => {
            if (section.includes('-')) {
                // if the section contains a dash, assume it is a range and extract the start and end numbers
                const [start, end] = section.split('-');
                const startNumber = Number(start);
                const endNumber = Number(end);

                if (
                    start.length == 0 ||
                    end.length == 0 ||
                    isNaN(startNumber) ||
                    isNaN(endNumber) ||
                    endNumber < startNumber
                ) {
                    // the range is invalid so return empty array
                    return [];
                }

                for (let i = startNumber; i <= endNumber; i++) {
                    result.push(i);
                }
            } else {
                // if the section does not contain a dash, assume it is a single number and add it directly to the result array
                const sectionNumber = Number(section);
                if (isNaN(sectionNumber)) {
                    // the section number is invalid so return empty array
                    return [];
                }
                result.push(Number(section));
            }
        });

        // filter out any duplicate entries then sort the array smallest to largest and return the result array containing all the section numbers
        return result
            .filter((number, index, array) => {
                return array.indexOf(number) === index;
            })
            .sort((a, b) => a - b);
    };

    public setHeaderAndFooterAsync = async (
        location: 'header' | 'footer',
        content: string,
        position: 'start' | 'end',
        clearExisting: boolean,
        sections: 'all' | string,
        bookmarkPrefix: string,
    ): Promise<void> => {
        return new Promise((resolve) => {
            Word.run(async (context) => {
                // Determine which section numbers should contain a marking
                const sectionsArr: number[] = this.parseSections(sections);

                let headerFooterInsertLocation: Word.InsertLocation;
                if (position == 'start') headerFooterInsertLocation = Word.InsertLocation.start;
                else headerFooterInsertLocation = Word.InsertLocation.end;

                // Get all the sections in the document
                const documentSections = context.document.sections;
                context.document.sections.load();

                await context.sync();

                // Loop through each section
                let sectionNumber = 0;
                for (const section of documentSections.items) {
                    // loop through each header/footer type
                    for (const headerFooterType of Object.values(Word.HeaderFooterType)) {
                        // Generate a bookmark name consistent with othe platforms to ensure interoperability
                        // Should look like Titus1HeaderPrimary
                        const bookmarkName =
                            bookmarkPrefix +
                            (sectionNumber + 1) +
                            (location == 'header' ? 'Header' : 'Footer') +
                            headerFooterType;

                        // Remove the titus marking and bookmark if it exists
                        let markingRange: Word.Range | null;
                        try {
                            markingRange = context.document.getBookmarkRange(bookmarkName);
                            await context.sync();
                        } catch (exception) {
                            markingRange = null;
                        }

                        if (markingRange) {
                            markingRange.delete();
                            context.document.deleteBookmark(bookmarkName);
                            await context.sync();
                        }

                        // If this section should include a Titus marking insert it
                        if (sections.toLowerCase() == 'all' || sectionsArr.includes(sectionNumber + 1)) {
                            let headerFooterBody: Word.Body;
                            if (location == 'header') {
                                headerFooterBody = section.getHeader(headerFooterType);
                            } else {
                                headerFooterBody = section.getFooter(headerFooterType);
                            }

                            // If configured clear existing non-Titus content
                            if (clearExisting) headerFooterBody.clear();

                            // Then insert the titus marking
                            const paragraph = headerFooterBody.insertParagraph('', headerFooterInsertLocation);
                            const range = paragraph.insertHtml(content, 'Replace');
                            range.insertBookmark(bookmarkName);

                            await context.sync();
                        }
                    }

                    sectionNumber++;
                }
                resolve();
            });
        });
    };
}
