import { DictionaryInterface } from 'titus-ts/dist/js/Common/Util/DictionaryInterface';
import BuiltInProperties from 'titus-ts/dist/js/Document/BuiltInProperties';
import { AddinApi } from './AddinApi';

export class ExcelApi extends AddinApi {
    public getBodyAsTextAsync = (): Promise<string> => {
        return new Promise<string>((resolve, reject) => {
            Excel.run(async (context) => {
                const sheets: Excel.Range[] = [];
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const data: any[] = [];
                const worksheets = context.workbook.worksheets;
                context.load(worksheets);
                await context.sync();
                if (!worksheets.items) reject('WorkSheet item can not be null or undefined');
                else {
                    worksheets.items.forEach((item) => {
                        const sheet = item.getUsedRange().load('values');
                        sheets.push(sheet);
                    });
                    await context.sync();
                    sheets.forEach((sheet) => {
                        const sheetData = sheet.values.reduce(
                            (currentValue, newValue) => currentValue.concat(newValue),
                            [],
                        );
                        data.push(sheetData);
                    });
                    resolve(data.toString());
                }
            });
        });
    };

    public getCustomProperties = (
        requestedCustomPropertiesNames: string[],
    ): Promise<DictionaryInterface<string | string[]>> => {
        return new Promise<DictionaryInterface<string | string[]>>((resolve, reject) => {
            Excel.run(async (context) => {
                const customProperties = context.workbook.properties.custom;
                const selectedCustomProperties: DictionaryInterface<string | string[]> = {};
                context.load(customProperties);
                await context.sync();
                if (!customProperties.items) reject('Excel get Custom Properties failed');
                else {
                    customProperties.items.forEach((item) => {
                        if (requestedCustomPropertiesNames.includes(item.key)) {
                            selectedCustomProperties[item.key] = item.value.split(';');
                        }
                    });
                }
                resolve(selectedCustomProperties);
            });
        });
    };

    public setCustomProperties(
        selectedCustomProperties: DictionaryInterface<string | string[]>,
        associatedCustomPropertiesNames: string[],
    ): Promise<void> {
        return new Promise<void>((resolve) => {
            Excel.run(async (context) => {
                const fields = Object.getOwnPropertyNames(selectedCustomProperties);
                const customProperties = context.workbook.properties.custom;
                context.load(customProperties);
                await context.sync();
                for (let i = 0; i < associatedCustomPropertiesNames.length; i++) {
                    customProperties.getItemOrNullObject(associatedCustomPropertiesNames[i])?.delete();
                }
                await context.sync();
                for (let i = 0; i < fields.length; i++) {
                    const valueString =
                        typeof selectedCustomProperties[fields[i]] == 'string'
                            ? selectedCustomProperties[fields[i]]
                            : (selectedCustomProperties[fields[i]] as string[]).join(';');
                    context.workbook.properties.custom.add(fields[i], valueString);
                }
                await context.sync();
                resolve();
            });
        });
    }

    public getProperties = (): Promise<BuiltInProperties> => {
        return new Promise<BuiltInProperties>((resolve, reject) => {
            Excel.run(async (context) => {
                const properties = context.workbook.properties;
                context.load(properties);
                await context.sync();
                if (!properties) reject('Excel get properties failed');
                else {
                    const docProperties: BuiltInProperties = {
                        Author: properties.author,
                        Category: properties.category,
                        Comments: properties.comments,
                        CreationDate: properties.creationDate?.toDateString(),
                        Keywords: properties.keywords,
                        LastAuthor: properties.lastAuthor,
                        LastSaveTime: '',
                        RevisionNumber: properties.revisionNumber?.toString(),
                        Subject: properties.subject,
                        Template: '',
                        Title: properties.title,
                        LastPrintDate: '',
                        NumberOfPages: '',
                    };
                    resolve(docProperties);
                }
            });
        });
    };

    public getHeaderContent = (): Promise<string> => {
        return new Promise((resolve) => {
            Excel.run(async (context) => {
                const activeWorksheet = context.workbook.worksheets.getActiveWorksheet();
                const headersAndFooters = await this.getHeadersAndFooters(context, activeWorksheet);
                const headerContent = headersAndFooters.centerHeader;
                resolve(headerContent);
            });
        });
    };

    public getFooterContent = (): Promise<string> => {
        return new Promise((resolve) => {
            Excel.run(async (context) => {
                const activeWorksheet = context.workbook.worksheets.getActiveWorksheet();
                const headersAndFooters = await this.getHeadersAndFooters(context, activeWorksheet);
                const footerContent = headersAndFooters.centerFooter;
                resolve(footerContent);
            });
        });
    };

    public setHeaderAndFooterAsync = async (location: 'header' | 'footer', headerFooterText: string): Promise<void> => {
        return new Promise((resolve) => {
            Excel.run(async (context) => {
                const worksheets = context.workbook.worksheets;
                context.load(worksheets);
                await context.sync();
                const worksheetsItems = worksheets.items;
                for (const worksheetItem of worksheetsItems) {
                    const headersAndFooters = await this.getHeadersAndFooters(context, worksheetItem);
                    if (location === 'header') {
                        headersAndFooters.set({
                            leftHeader: '',
                            centerHeader: '',
                            rightHeader: '',
                        });
                        headersAndFooters.set({
                            centerHeader: new FormattedText(headerFooterText).toExcelString(),
                        });
                    } else if (location === 'footer') {
                        headersAndFooters.set({
                            leftFooter: '',
                            centerFooter: '',
                            rightFooter: '',
                        });
                        headersAndFooters.set({
                            centerFooter: new FormattedText(headerFooterText).toExcelString(),
                        });
                    }
                    worksheetItem.getRange().format.autofitColumns();
                }
                await context.sync();
                resolve();
            });
        });
    };

    public getPlatform = (): string => {
        const platformType = this.getPlatformType();
        let platform: string;
        switch (platformType) {
            case 'Android':
                platform = 'ExcelAndroid';
                break;
            case 'iOS':
            case 'Mac':
                platform = 'ExcelIOS';
                break;
            case 'OfficeOnline':
                platform = 'ExcelWebApp';
                break;
            default:
                platform = 'Excel';
                break;
        }
        return platform;
    };

    private getHeadersAndFooters = (
        context: Excel.RequestContext,
        worksheet: Excel.Worksheet,
    ): Promise<Excel.HeaderFooter> => {
        return new Promise(async (resolve) => {
            const headerFooter = worksheet.pageLayout.headersFooters.defaultForAllPages;
            headerFooter.load({ $all: true });
            await context.sync();
            resolve(headerFooter);
        });
    };
}

class FormattedText {
    formatting: TextFormatting;
    value: (string | FormattedText)[] = [];

    constructor(html: string, formatting?: TextFormatting) {
        this.formatting = formatting || new TextFormatting();

        if (html) {
            const parser = new DOMParser();
            const doc = parser.parseFromString(html, 'text/html');

            const body = doc.body;
            // if the html couldnt be parsed return
            if (!body) {
                return;
            }

            // process each node to extract text and formatting
            this.value = [this.processNode(body, new TextFormatting())];
        }
    }

    private processNode(node: ChildNode, originalFormatting: TextFormatting): string | FormattedText {
        if (node.nodeType === Node.ELEMENT_NODE) {
            const element = node as HTMLElement;
            const tagName = element.tagName.toLowerCase();

            // create a formatted text object then apply the previous styles and update them based on this element
            const formattedText = new FormattedText('');

            formattedText.formatting = new TextFormatting();
            Object.assign(formattedText.formatting, originalFormatting);

            formattedText.formatting.updateFromTagName(tagName);
            formattedText.formatting.updateFromStyle(element.style);

            // process child elements
            if (element.childNodes.length > 0) {
                element.childNodes.forEach((childNode) => {
                    if (childNode.nodeType === Node.ELEMENT_NODE || childNode.nodeType === Node.TEXT_NODE) {
                        formattedText.value.push(this.processNode(childNode, formattedText.formatting));
                    }
                });
            }

            // divs should act like paragraphs so if we have one end it with a new line
            if (tagName == 'div') {
                formattedText.value.push('\r');
            }

            return formattedText;
        } else if (node.nodeType === Node.TEXT_NODE) {
            return node.nodeValue || '';
        } else {
            return '';
        }
    }

    // to string, set format codes, child elements ,revert format codes
    public toExcelString(): string {
        let excelString = '';

        for (const value of this.value) {
            if (typeof value === 'string') {
                excelString += value;
            } else {
                const formattedText = value as FormattedText;
                excelString += formattedText.formatting.getFormatCodesToSet(this.formatting);
                excelString += formattedText.toExcelString();
                excelString += formattedText.formatting.getFormatCodesToRevert(this.formatting);
            }
        }

        return excelString;
    }
}
class TextFormatting {
    // Default Values
    superscript = false;
    subscript = false;
    italic = false;
    bold = false;
    underline = false;
    doubleUnderline = false;
    strikethrough = false;
    fontFamily = 'Sans Serif';
    fontSize = '11';
    color = '000000';
    alignment: 'Left' | 'Center' | 'Right' = 'Left';

    updateFromTagName(tagName: string): void {
        switch (tagName) {
            case 'strong':
                this.bold = true;
                break;
            case 'b':
                this.bold = true;
                break;
            case 'em':
                this.italic = true;
                break;
            case 'i':
                this.italic = true;
                break;
            case 'u':
                this.underline = true;
                break;
            case 'sup':
                this.superscript = true;
                break;
            case 'sub':
                this.subscript = true;
                break;
            default:
                break;
        }
    }
    updateFromStyle(styles: CSSStyleDeclaration): void {
        if (styles.color) {
            this.color = this.parseColor(styles.color);
        }
        if (styles.fontSize) {
            this.fontSize = this.parseFontSize(styles.fontSize);
        }
        if (styles.fontFamily) {
            this.fontFamily = styles.fontFamily;
        }
        switch (styles.textAlign?.toLowerCase()) {
            case 'left':
                this.alignment = 'Left';
                break;
            case 'center':
                this.alignment = 'Center';
                break;
            case 'right':
                this.alignment = 'Right';
                break;
            default:
                break;
        }
    }
    getFormatCodesToRevert(originalFormating: TextFormatting): string {
        return this.getUpdateExcelFormatCodes(this, originalFormating);
    }
    getFormatCodesToSet(originalFormating: TextFormatting): string {
        return this.getUpdateExcelFormatCodes(originalFormating, this);
    }

    private parseColor(colorSting: string): string {
        // example #AF243C
        {
            const hexPattern = /^\s*#?([a-fA-F\d]{6})\s*$/;
            const match = colorSting.match(hexPattern);
            if (match) {
                // Extract the RGB components
                const hex = match[1];

                return hex;
            }
        }

        // example rgb(230, 0, 0)
        {
            const rgbPattern = /^rgb\s*\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)\s*$/;
            const match = colorSting.match(rgbPattern);

            if (match) {
                // Extract the RGB components
                const [r, g, b] = match.slice(1, 4).map(Number);

                // Ensure the values are within the valid range (0-255)
                if (r > 255 || g > 255 || b > 255) {
                    return '';
                }

                // Convert the RGB components to a hex string
                const hex = `${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;
                return hex;
            }
        }

        return '';
    }

    private parseFontSize(colorSting: string): string {
        // example #21px
        {
            const pixelPattern = /^\s*(\d{1,3})\s*[pP][xX]\s*$/;
            const match = colorSting.match(pixelPattern);
            if (match) {
                // Extract the font size
                const fontSize = match[1];

                return fontSize;
            }
        }

        return '';
    }

    private getUpdateExcelFormatCodes(currentFormat: TextFormatting, targetFormat: TextFormatting): string {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const ExcelformatCodeMap: Record<string, string | ((value: any) => string)> = {
            superscript: TextFormatting.ExcelHeaderFormatCodes.superscript,
            subscript: TextFormatting.ExcelHeaderFormatCodes.subscript,
            italic: TextFormatting.ExcelHeaderFormatCodes.italic,
            bold: TextFormatting.ExcelHeaderFormatCodes.bold,
            underline: TextFormatting.ExcelHeaderFormatCodes.underline,
            doubleUnderline: TextFormatting.ExcelHeaderFormatCodes.doubleUnderline,
            strikethrough: TextFormatting.ExcelHeaderFormatCodes.strikethrough,
            fontSize: (value: string) => `&${value}`,
            color: (value: string) => `&K${value}`,
            fontFamily: (value: string) => `&"${value}"`,
            alignment: (value: string) => {
                switch (value) {
                    case 'Left':
                        return TextFormatting.ExcelHeaderFormatCodes.alignLeft;
                    case 'Center':
                        return TextFormatting.ExcelHeaderFormatCodes.alignCenter;
                    case 'Right':
                        return TextFormatting.ExcelHeaderFormatCodes.alignRight;
                    default:
                        return '';
                }
            },
        };

        return Object.entries(ExcelformatCodeMap).reduce((codes, [key, codeOrFormatter]) => {
            const currentValue = currentFormat[key as keyof TextFormatting];
            const targetValue = targetFormat[key as keyof TextFormatting];
            if (currentValue !== targetValue) {
                const code = typeof codeOrFormatter === 'function' ? codeOrFormatter(targetValue) : codeOrFormatter;
                codes += code;
            }
            return codes;
        }, '');
    }

    static ExcelHeaderFormatCodes = {
        superscript: '&X',
        subscript: '&Y',
        italic: '&I',
        bold: '&B',
        underline: '&U',
        doubleUnderline: '&E',
        strikethrough: '&S',
        alignLeft: '&L',
        alignCenter: '&C',
        alignRight: '&R',
    };
}
