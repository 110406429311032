import { TitusAuth } from 'titus-auth';
import { jwtDecode } from 'jwt-decode';

export class AuthService {
    private titusAuth: TitusAuth;

    public constructor(titusAuth: TitusAuth) {
        this.titusAuth = titusAuth;
    }

    public getTitusToken = async (): Promise<string> => {
        let titusToken = this.fetchTitusToken();

        // if we have no token for this session try to get a new one
        if (!titusToken) {
            titusToken = await this.getNewTitusToken();
        }

        return titusToken;
    };

    private fetchTitusToken = (): string | null => {
        const storedTitusTokenExpiration = parseInt(sessionStorage.getItem('titusTokenExpiration') || '-1');
        const storedTitusToken = sessionStorage.getItem('titusToken');

        // if we have a token stored and it hasn't expired
        if (storedTitusToken && storedTitusTokenExpiration > new Date().getTime()) {
            return storedTitusToken;
        }

        return null;
    };

    private getNewTitusToken = async (): Promise<string> => {
        debugger;
        const userSmtpAddress = await this.getSmtpAddress();

        try {
            const authClient = await this.titusAuth.getTitusAuthClientWithOfficeConfig(userSmtpAddress || '');
            const titusToken = await authClient.renewTitusJWTToken();

            if (titusToken) {
                this.cacheTitusToken(titusToken);
            }

            return titusToken;
        } catch {
            throw new Error(
                'Error retrieving Titus token. A Popup Blocker could be preventing the authentication dialogs.',
            );
        }
    };

    private getSmtpAddress = async (): Promise<string> => {
        //If we're in Outlook we can get it straight from the API
        let userSmtpAddress = Office?.context?.mailbox?.userProfile?.emailAddress;

        //Check if IdentityAPI@1.3 is supported, its not in 'dialogs' or certain other scenarios and can kill the addin if called!!!
        const isIdentityApiSupported = Office.context.requirements.isSetSupported('IdentityAPI', '1.3');
        if (!userSmtpAddress && isIdentityApiSupported) {
            //In the Office apps we need to get an Access Token and read the SMTP address off the token
            try {
                //Define a custom interface for the JwtPayload so we can access the custom properties returned
                interface CustomJwtPayload {
                    preferred_username: string;
                }
                console.log('About to call getAccessToken....');
                const userTokenEncoded = await Office?.auth.getAccessToken();
                console.log('Finished call to getAccessToken....');
                const userToken = jwtDecode<CustomJwtPayload>(userTokenEncoded);
                userSmtpAddress = userToken?.preferred_username;
            } catch (error) {
                console.log('Unable to get SMTP address from getAccessToken(), falling back to auth popups.');
            }
        }

        console.log('Address: ' + userSmtpAddress);
        return userSmtpAddress;
    };

    private cacheTitusToken = (titusToken: string): void => {
        const tokenExpiry = new Date().getTime() + 30 * 60000;

        sessionStorage.setItem('titusToken', titusToken);
        sessionStorage.setItem('titusTokenExpiration', tokenExpiry.toString());
    };
}
